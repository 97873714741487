import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Front Squat 2-2-2-2 to a 2RM`}</p>
    <p>{`Glute Ham Raise 4-4-4-4`}</p>
    <p>{`then,`}</p>
    <p>{`15:00 EMOM of:`}</p>
    <p>{`1-Power Clean`}</p>
    <p>{`1-Hang Squat Clean`}</p>
    <p>{`1-Front Squat`}</p>
    <p>{`*`}{`using 80% 1RM of lightest movement`}</p>
    <p>{`rest until minute 17:00 then,`}</p>
    <p>{`21-15-9 reps each of:`}</p>
    <p>{`Hang Power Cleans (135/95)`}</p>
    <p>{`Pullups`}</p>
    <p>{`For time.`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`Our Annual free Thanksgiving morning WOD will be Thursday at
9:00am.  All other classes and open gym will be cancelled for the day as
well as the 6:00am class on Friday.  Classes will be back to normal
starting at 10:30am on Friday.`}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`The next Strength Program will start Nov 26th.  For more info email
Eric at: fallscitystrength\\@gmail.com.`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      